export const API = {
	LOGIN: {
		url: 'auth/login',
		type: 'post'
	},
	DOMICILE: {
		url: 'public/domicile',
		type: 'get'
	},

	LEADS: {
		url: 'talent',
		type: 'get'
	},
	LEADS_CREATE: {
		url: 'outsource',
		type: 'post'
	},
	LEADS_UPDATE: {
		url: 'outsource',
		type: 'put'
	},
	LEADS_DELETE: {
		url: 'outsource',
		type: 'delete'
	},
	LEADS_GET: {
		url: 'outsource/get',
		type: 'get'
	},
	IMAGE_CREATE: {
		url: 'image',
		type: 'post'
	},
	IMAGE_DELETE: {
		url: 'image',
		type: 'delete'
	},
	USER_GET: {
		url: 'user',
		type: 'get'
	},
	USER_CREATE: {
		url: 'user',
		type: 'post'
	},
	USER_UPDATE: {
		url: 'user',
		type: 'put'
	},
	APPLICANT: {
		url: 'outsource',
		type: 'get'
	},
	APPLICANT_UPDATE: {
		url: 'outsource',
		type: 'put'
	},
	APPLICANT_APPROVE: {
		url: 'outsource/approve',
		type: 'put'
	},
	JOB_GET: {
		url: 'position_loker',
		type: 'get'
	},
	JOB_CREATE: {
		url: 'position_loker',
		type: 'post'
	},
	JOB_EDIT: {
		url: 'position_loker',
		type: 'put'
	},
	INBOX_GET: {
		url: 'inbox',
		type: 'get'
	},
	INBOX_PUT: {
		url: 'inbox/read',
		type: 'put'
	},
	TERMS_LIST: {
		url: 'term',
		type: 'get'
	},
	TERMS_EDIT: {
		url: 'term',
		type: 'put'
	},
	TERMS_CREATE: {
		url: 'term',
		type: 'post'
	},
	COMPANY_LIST: {
		url: 'company',
		type: 'get'
	},
	COMPANY_GET: {
		url: 'company/get',
		type: 'get'
	},
	COMPANY_CREATE: {
		url: 'company',
		type: 'post'
	},
	COMPANY_ALL: {
		url: 'company/list',
		type: 'get'
	},
	COMPANY_UPDATE: {
		url: 'company',
		type: 'put'
	},
	COMPANY_DELETE: {
		url: 'company',
		type: 'delete'
	},
	TALENT_INTERVIEW: {
		url: 'talent/status/assign',
		type: 'put'
	},
	TALENT_INACTIVE: {
		url: 'talent/status/inactive',
		type: 'put'
	},
	TALENT_OPEN: {
		url: 'talent/status/open',
		type: 'put'
	},
	TALENT_REJECT: {
		url: 'talent/status/open',
		type: 'put'
	},
	TALENT_WORK: {
		url: 'talent/status/work',
		type: 'put'
	},
	TALENT_WORK_WITHOUT_TASK: {
		url: 'talent/status/work/without_task',
		type: 'put'
	},
	TALENT_APPROVE_PRINCIPAL: {
		url: 'talent/status/approve_pricipal',
		type: 'put'
	},
	TALENT_CREATE: {
		url: 'talent',
		type: 'post'
	},
	TALENT_UPDATE: {
		url: 'talent',
		type: 'put'
	},
	JOB_HISTORY: {
		url: 'talent/job_history/',
		type: 'get'
	},
	USER_ASSIGN: {
		url: 'talent/user/assign',
		type: 'get'
	},
	USER_WORK: {
		url: 'talent/user/work',
		type: 'get'
	},
	USER_DASHBOARD_ASSIGN: {
		url: 'talent/user/summary_assign',
		type: 'get'
	},
	USER_DASHBOARD_WORK: {
		url: 'talent/user/summary_work',
		type: 'get'
	},
	GET_AGREEMENT: {
		url: 'doc_agreement_template',
		type: 'get'
	},
	EDIT_AGREEMENT: {
		url: 'doc_agreement_template',
		type: 'put'
	},
	CREATE_AGREEMENT: {
		url: 'doc_agreement_template',
		type: 'post'
	},
	DELETE_AGREEMENT: {
		url: 'doc_agreement_template',
		type: 'delete'
	},
}