import { AES, enc } from "crypto-js";
export const Securitykey = process.env.REACT_APP_SECRET_CODE;

export const decrypt = (data) => {
    if (data) {
        try {
            var bytes = AES.decrypt(data, Securitykey);
            var decryptedData = bytes.toString(enc.Utf8);
            return decryptedData;
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
}

export const encrypt = (data) => {
    try {
        const encryptedData = AES.encrypt(data, Securitykey).toString();
        return encryptedData;
    } catch (error) {
        return null;
    }
}
