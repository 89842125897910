import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Api } from '../../utils/apiServer'
import { API } from '../../utils/api'


export const getLeadsContent = createAsyncThunk('/leads/content', async (params) => {
    const response = await Api(params)
    return response;
})

export const leadsSlice = createSlice({
    name: 'leads',
    initialState: {
        isLoading: false,
        leads: [],
        page: 1,
        lastPage: 1


    },
    reducers: {
        addNewLead: (state, action) => {
            let { newLeadObj } = action.payload
            state.leads = [...state.leads, newLeadObj]
        },

        deleteLead: (state, action) => {
            const params = {
                access: 'auth',
                url: API.LEADS_DELETE.url,
                method: API.LEADS_DELETE.type,
                reqBody: {
                    outsource_id: action.payload.index.outsource_id
                }
            }
            const response = Api(params)
            // let {index} = action.payload
            // state.leads.splice(index, 1)
        }
    },

    extraReducers: {
        [getLeadsContent.pending]: state => {
            state.isLoading = true
        },
        [getLeadsContent.fulfilled]: (state, action) => {
            state.leads = action.payload.data
            state.page = action.payload.data.page
            state.latPage = action.payload.data.lastPage
            state.isLoading = false
        },
        [getLeadsContent.rejected]: state => {
            state.isLoading = false
        },
    }
})

export const { addNewLead, deleteLead } = leadsSlice.actions

export default leadsSlice.reducer